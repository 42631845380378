<template>
  <div id="about-preview">
    <div class="about">
      <div class="dude-selector">
        <form>
          <input type="radio" id="band" value="band" v-model="picked">
          <label for="band">The Band</label>
          <input type="radio" id="sean" value="sean" v-model="picked">
          <label for="sean">Sean</label>
          <input type="radio" id="ryan" value="ryan" v-model="picked">
          <label for="ryan">Ryan</label>
          <input type="radio" id="caleb" value="caleb" v-model="picked">
          <label for="caleb">Caleb</label>
          <input type="radio" id="hunter" value="hunter" v-model="picked">
          <label for="hunter">Hunter</label>
          <input type="radio" id="dolan" value="dolan" v-model="picked">
          <label for="dolan">Justin</label>
        </form>
      </div>
      <div class="bio-container" v-if="dude">
        <bio-card>
          <template slot="image">
            <img :src="dude.image" ref="image">
          </template>
          <template slot="text">
            <p>
              {{ dude.description }}
            </p>
          </template>
        </bio-card>
      </div>
      <div class="form-container" v-if="dude">
        <form @submit.prevent="uploadAndSave">
          <div>
            <input type="file" id="file" class="input-file" @change="holdFile"/>
            <label class="file-label" for="file">{{uploadButtonText}}</label>
          </div>
          <textarea v-model="dude.description"></textarea>
          <div class="action-area">
            <button class="button" type="submit">Save</button>
            <div v-if="dbErrors.length > 0">
              <div v-for="(error, index) in dbErrors" :key="index" class="error">{{ error }}</div>
            </div>
            <div v-if="dbInfo.length > 0">
              <div v-for="(info, index) in dbInfo" :key="index" class="info">{{ info }}</div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ThcBiographies from "@/views/home/sections/ThcBiographies.vue";
import BioCard from "@/components/BioCard.vue";
import { db, storage } from '../../../firebase';
import { firebase } from '@firebase/app';

export default {
  name: "AboutPreview",
  props: {},
  components: {
    ThcBiographies,
    BioCard,
  },
  data: function () {
    return {
      picked: 'band',
      dude: null,
      dbErrors: [],
      dbInfo: [],
      fileToUpload: null,
      uploadButtonText: 'Choose Image',
    }
  },
  methods: {
    fetchBio: function () {
      let self = this;
      db.collection('biographies').doc(this.picked).get().then( snapshot => { 
        self.dude = snapshot.data();
      });
    },
    holdFile: function (event) {
      let self = this;
      this.fileToUpload = event.target.files[0];
      this.uploadButtonText = this.fileToUpload.name;
      let reader = new FileReader();
      reader.onload = function (e) {
        self.$refs.image.src = e.target.result;
      };
      reader.readAsDataURL(this.fileToUpload);
    },
    // TODO: component 'action-area'
    uploadAndSave: function () {
      let self = this;
      if (self.fileToUpload) {
        let uploadTask = storage.ref().child(`band-bio/${this.picked}-${self.fileToUpload.name}`).put(self.fileToUpload);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
          function (snapshot){
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            self.uploadButtonText = `${Math.round(progress)}%`;
            console.log(`file upload: ${progress}%`);
          },
          function (error) {
            switch (error.code) {
              case 'storage/unauthorized':
                console.error('unauthorized ', error);
                break;
              case 'storage/canceled':
                console.error('canceled ', error);
                break;
              case 'storage/unknown':
                console.error('unknown error ', error);
                break;
            }
          },
          function () {
            uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
              console.log(downloadURL);
              self.uploadButtonText = 'Change Image';
              self.dude.image = downloadURL;
              self.saveDocument();
            })
          });
      } else {
        console.log('theres no img to upload');
        self.saveDocument();
      }
    },
    saveDocument: function () {
      let self = this;
      db.collection('biographies').doc(this.picked).set(this.dude).then(function () {
        console.log("Document successfully written!");
        self.dbInfo.push("Saved!");
        let clearInfo = setTimeout(() => { 
          self.dbInfo = [];
        }, 3000);
      }).catch(function(error) {
        self.dbErrors.push(error);
        console.error("Error writing document: ", error);
      });
    },
  },
  computed: {},
  watch: {
    picked: function (newVal, oldVal) {
      this.fetchBio();
    }
  },
  created() {
    this.fetchBio();
  }
};
</script>

<style lang="scss" scoped>
.about {
  background-image: linear-gradient(#111 0%, #153646 20%);
}
.bio-container {
  display: flex;
  flex-wrap: wrap;
}
label {
  color: #ccc;
  margin-right: 8px;
}
.form-container textarea {
  width: 100vw;
  min-height: 80px;
}
.input-file {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
  z-index: -1;
}

.input-file:focus + label,
.input-file + label:hover {
    background: linear-gradient(#000, #333);
}

.file-label {
    font-size: .9em;
    color: #ccc;
    background: linear-gradient(#333, #000);
    display: block;
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
    display: inline-block;
}
</style>
