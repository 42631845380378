<template>
  <div class="messages">
    <div v-for="(msg, i) in messages" :key="`msg-${i}`" class="message">
      <p>{{ msg.email }}</p>
      <p>{{ msg.body }}</p>
    </div>
  </div>
</template>

<script>
import { db } from '@/firebase';
import { firebase } from '@firebase/app';

export default {
  name: 'Messages',
  firestore() {
    return {
      messages: db.collection('messages').orderBy('email'),
    }
  }
}
</script>

<style lang="scss" scoped>
.message {
  // border: 1px solid #333;
  border-radius: 4px;
  // margin: 2px;
  padding: 24px;
  background-color: rgba(180, 180, 180, 0.9);  
}
</style>