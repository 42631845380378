<template>
  <div id="gallery-preview">
    <div class="gallery-form">
      <div><input v-model="tag.tag" type="text" placeholder="Tag Id"></div>
      <div><input v-model="tag.title" type="text" placeholder="Title"></div>
      <div><input v-model="tag.description" type="text" placeholder="Description"></div>
      <div><vue-ctk-date-time-picker
        v-model="tag.date"
        only-date
        formatted="YYYY-MM-DD"
        format="YYYY-MM-DD"
        placeholder="Date"/></div>
      <div><input type="button"
      class="button"
      value="Add Tag"
      @click="saveTag"></div>
    </div>
    <gallery-panel is-admin @delete="deleteTag" />
  </div>
</template>

<script>
import { db } from '@/firebase';
import { storage } from '@/firebase';
import { firebase } from '@firebase/app';
import GalleryPanel from '../../../components/GalleryPanel';

export default {
  name: 'GalleryPreview',
  components: { GalleryPanel },
  data: function () {
    return {
      tag: {
        tag: null,
        title: null,
        description: null,
        date: null,
      }
    }
  },
  methods: {
    saveTag() {
      db.collection('galleryTags').doc().set(this.tag)
        .then(() => {
          console.log('Tag Created!  Now go to Cloudinary and tag some photos.');
        })
        .catch((error) => {
          console.error('Error creating tag:', error);
        });
    },
    deleteTag(tag) {
      db.collection('galleryTags').doc(tag['.key']).delete()
        .then(() => {
          console.log('Tag Deleted');
          this.tag = {};
        })
    },
  },
  firestore() {
    return {
      tags: db.collection('galleryTags'),
      // groups: db.collection('gallery-group').orderBy('date', 'desc'),
    };
  },
}
</script>

<style lang="scss" scoped>
.gallery-form {
  display: flex;
  flex-flow: row wrap;
}
</style>
