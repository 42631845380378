<template>
  <section class="thc-hero">
    <div class="hero-inner">
      <h1>The Halfway Crooks</h1>
      <h3 class="sub-title">Soul Stained Rock and Roll</h3>
      <ul>
        <li><a href="#events" v-scroll-to="'#events'">Events</a></li>
        <!-- <li><a href="#media" v-scroll-to="'#gallery'">Gallery</a></li> -->
        <li><router-link to="/gallery">Gallery</router-link></li>
        <li><a href="#theband" v-scroll-to="'#thc-bio'">The Band</a></li>
        <li><a href="#contact" v-scroll-to="'#contact'">Contact Us</a></li>
        <li>
          <a class="social-icon" href="https://www.facebook.com/HalfwayCrooksBand/" target="_blank" rel="noreferrer" aria-label="Visit our Facebook page"><font-awesome-icon class="facebook-icon" :icon="facebook" :style="dynamicColor" /></a>
          <a class="social-icon" href="https://www.instagram.com/thehalfwaycrooksband/" target="_blank" rel="noreferrer" aria-label="Visit our Instagram page"><font-awesome-icon class="instagram-icon" :icon="instagram" :style="dynamicColor" /></a>
        </li>
        <li>
          <a class="social-icon" href="https://www.youtube.com/channel/UCki1aIPu3ycnjsCsa4qxq1g" target="_blank" rel="noreferrer" aria-label="Visit our youtube page"><font-awesome-icon class="youtube-icon" :icon="youtube" :style="dynamicColor" /></a>
          <a class="social-icon" href="https://soundcloud.com/thehalfwaycrooksmusic?fbclid=IwAR3ajmp9mRaq7WMZU_mbM_sHIqtbRn5rvxBFHmRT0oG-9s5ljDXawgs7JPQ" target="_blank" rel="noreferrer" aria-label="Visit our Soundcloud page"><font-awesome-icon class="soundcloud-icon" :icon="soundcloud" :style="dynamicColor" /></a>
        </li>
      </ul>
    </div>
    <font-awesome-icon class="cog-icon" @click="goToAdmin" :icon="cog" :style="dynamicColor" />
  </section>
</template>

<script>
import { library } from '@fortawesome/vue-fontawesome';
import { faFacebook, faYoutube, faSoundcloud, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faCog } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'ThcHero',
  data: function data() {
    return {
      dynamicBackground: {},
      dynamicColor: {},
    };
  },
  methods: {
    goToAdmin: function () {
      this.$router.push('/admin/login');
    }
  },
  computed: {
    facebook: function getFacebookIcon() {
      return faFacebook;
    },
    youtube: function getYoutubeIcon() {
      return faYoutube;
    },
    soundcloud: function getSoundcloudIcon() {
      return faSoundcloud;
    },
    instagram: function getInstagramIcon() {
      return faInstagram;
    },
    cog: function getCog() {
      return faCog;
    }
  },
};
</script>

<style lang="scss" scoped>
$break-small: 400px;
$break-medium: 600px;
$purple: #32122c;
.thc-hero {
    height: 100vh;
    /* height: 400px; */
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ccc;
    background: url('../../../assets/img/thc-logo-hero-2.jpg');
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-color: #000;
    @media (max-width: $break-medium) {
      background-attachment: scroll;
      background-size: 53rem;
      // transform: rotate(-30deg);
      // width: 200vw;
    }
    @media (max-width: $break-small) {
      // height: 80vh;
      background-position: 50% 70%;
    }
    text-shadow: 2px 2px 10px purple;
}

.thc-hero h1 {
    font-size: 2.5em;
    // opacity: .8;
}
.sub-title {
  opacity: .7;
}
.hero-inner {
    display: flex;
    flex-direction: column;
}

.hero-inner a {
    font-family: 'Montserrat Alternates', sans-serif;
    text-decoration: none;
    color: #ccc;
    // display: inline-block;
}

.hero-inner a:hover {
    color: teal;
}

.hero-inner ul {
    margin-top: 40px;
}

.hero-inner li {
    padding: 10px;
}

.social-icon {
  font-size: 1.2em;
  margin: 12px;
}

.cog-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  opacity: .4;
}
.cog-icon:hover {
  opacity: 1;
}

.color-change {
  color: #333;
  // opacity: .5;
  animation: colorchange 20s infinite;
}

@keyframes colorchange {
    0% { color: #ccc; }
    25% { color: green; }
    50% { color: purple; }
    75% { color: yellow; }
    100% { color: #ccc; }
  }

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@media (max-width: 600px) {
    .hero-inner h1 {
      font-size: 24pt;
    }
    p, .nav-link {
      font-size: 8pt;
    }
    #home {
      flex-direction: column-reverse;
    }
}
</style>
