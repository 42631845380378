<template>
  <div class="gallery-panel">
    <vue-gallery :images="galleryImages" :index="galleryIndex" @close="galleryIndex = null"/>
    <div v-for="tag in tags" :key="`tag-${tag.tag}`" class="tag-group">
      <button v-if="isAdmin" @click="$emit('delete',tag)">Delete</button>
      <tag-gallery :tag="tag" @select="selectImage"/>
    </div>
  </div>
</template>

<script>
import { db } from '@/firebase';
import { firebase } from '@firebase/app';
import axios from 'axios';
import TagGallery from '../components/TagGallery.vue';
import VueGallery from 'vue-gallery';

export default {
  name: 'GalleryPanel',
  components: { TagGallery, VueGallery },
  props: {
    isAdmin: Boolean,
  },
  data() {
    return {
      galleryIndex: null,
      galleryImages: [],
    };
  },
  methods: {
    selectImage({ idx, imageUrls }) {
      this.galleryIndex = idx;
      this.galleryImages = imageUrls;
    },
  },
  firestore() {
    return {
      tags: db.collection('galleryTags').orderBy('date', 'desc'),
    };
  },
}
</script>

<style lang="scss" scoped>
// .gallery-form {
//   display: flex;
//   flex-flow: row wrap;
// }
.tag-group {
  margin: 12px;
  padding: 12px;
  border: 1px solid rgb(128, 0, 128);
  background-color: rgba(51, 51, 51, .9);
  border-radius: 4px;
}
</style>