<template>
  <a id="contact">
    <div class="thc-contact">
      <div class="contact-form" v-if="!messageSent">
        <div><h1>Book us!</h1></div>
          <form class="ui form" @submit.prevent="onSubmit">
          <div class="email-form-group">
            <label for="email">Your email <span class="error"> {{ errors.first('email') }}</span></label>
            <input v-validate="'required|email'" name="email" id="email" type="email" placeholder="Who can we respond to?" v-model="contactEmail">
          </div>
          <div class="message-form-group">
            <label for="message">Message </label>
            <textarea id="message" placeholder="message" v-model="contactMessage"></textarea>
          </div>
          <!-- <button @click.once="addMessage" v-if="!messageSent">Send</button> -->
          <button class="button" type="submit">Send</button>
        </form>
      </div>
    </div>
    <div v-if="messageSent" class="sent-text">
      <h3>Thank you for contacting us.  You'll hear from us soon!</h3>
      <button @click="resetContactForm">Send Another?</button>
    </div>
  </a>
</template>

<script>
import { db } from '../../../firebase';

export default {
  name: 'ThcContact',
  components: {},
  data: function () {
    return {
      contactEmail: '',
      contactMessage: '',
      messageSent: false,
    }
  },
  computed: {},
  methods: {
    onSubmit: function () {
      let self = this;
      console.log(this.contactEmail, this.contactMessage);
      
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          let message = { email: this.contactEmail, body: this.contactMessage };
          db.collection("messages").add(message).then(function () {
            console.log('message sent.');
            self.messageSent = true;
          })
          .catch(function (error) {
            console.log('error: ', error);
          })
          self.messageSent = true;
        } else {
          console.log("BZZZTT... contact form not valid.");
        }
      });
    },
    resetContactForm: function () {
      this.contactEmail = '';
      this.contactMessage = '';
      this.messageSent = false;
    }
  },
}
</script>

<style lang="scss" scoped>
$purple: #441b3b;
.contact-form {
  display: flex;
  flex-flow: column nowrap;
  margin: 20px 10px 40px 10px;
}
.contact-form h1 {
  color: #ccc;
  margin-bottom: 20px;
}
.email-form-group,
.message-form-group {
  display: flex;
  flex-flow: column nowrap;
}
label {
  color: #ccc;
}
input,
textarea {
    border: 2px solid #153646;
    border-radius: 4px;
    -webkit-box-shadow: 
      inset 0 0 8px  rgba(0,0,0,0.1),
            0 0 16px rgba(0,0,0,0.1); 
    -moz-box-shadow: 
      inset 0 0 8px  rgba(0,0,0,0.1),
            0 0 16px rgba(0,0,0,0.1); 
    box-shadow: 
      inset 0 0 8px  rgba(0,0,0,0.1),
            0 0 16px rgba(0,0,0,0.1); 
    padding: 10px;
    background: #f1f1f1;
    margin: 0 0 10px 0;
}
textarea {
  min-height: 100px;
}
.sent-text {
  display: flex;
  flex-flow: column nowrap;
  // align-items: center;
  justify-content: center;
  text-align: center;
  color: #ccc;
  min-height: 100px;
  * {
    margin: 10px;
  }
}
.error {
  font-family: Arial, Helvetica, sans-serif;
  font-size: .5em;
  color: red;
}
</style>
