<template>
  <div id="thc-quote" v-if="quotes.length > 0" :style="withStyle && withStyle">
    <h3>{{ quotes[index].text }}</h3>
    <h4 v-if="quotes[index].source">- {{ quotes[index].source }}</h4>
  </div>
</template>

<script>
import { db } from '../../../firebase';

export default {
  name: 'ThcQuote',
  components: {},
  computed: {},
  props: {
    index: Number,
    withStyle: Object,
  },
  data: function () {
    return {
      quotes: [],
    }
  },
  methods: {},
  firestore() {
    return {
      quotes: db.collection('quotes').orderBy("order"),
    };
  },
}
</script>

<style lang="scss" scoped>
#thc-quote {
  text-align: center;
  color: #800080;
  padding: 20px;
}
#thc-quote h3 {
  @media (max-width: 600px) {
    font-size: .9em;
  }
  @media (min-width: 850px) {
    padding: 0 140px 0 140px;
  }
}
#thc-quote h4 {
  font-size: .7em;
}
</style>
