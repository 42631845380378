<template>
  <div id="events-preview">
    <div class="form-container">
      <form @submit.prevent="saveCurrentEvent">
        <div v-if="currentEvent"><h5>id: {{ currentEvent['.key'] }}</h5></div>
        <div v-if="currentEvent" class="form-group">
          <div><input type="text" v-model="currentEvent.venue" placeholder="Venue" required/></div>
          <div><vue-ctk-date-time-picker
            v-model="currentEvent.date"
            only-date
            formatted="YYYY-MM-DD"
            format="YYYY-MM-DD"
            placeholder="Date"/></div>
          <div><input type="text" v-model="currentEvent.timeBegin" placeholder="Start time"/></div>
          <div><input type="text" v-model="currentEvent.timeEnd" placeholder="End Time"/></div>
          <div><input type="text" v-model="currentEvent.description" placeholder="Misc Info"/></div>
        </div>
        <div class="form-group" v-if="currentEvent">
          <div>
            <!-- <input type="checkbox" id="event-active" :value="true" v-model="currentEvent.active"> -->
            <!-- <label for="event-active" class="checkbox-label">Active</label> -->
          </div>
        </div>
        <div class="form-group">
          <div>
            <input type="file" id="file" class="input-file" @change="holdFile"/>
            <label class="file-label" for="file">{{uploadButtonText}}</label>
          </div>
        </div>
        <div class="form-group">
          <div><input class="button" type="button" @click="clearCurrentEvent" value="clear"></div>
          <div><input class="button" type="submit" value="save"/></div>
          <div><input class="button" type="button" @click="deleteCurrentEvent" value="delete"/></div>
        </div>
      </form>
      <div class="current-event-card" v-if="currentEvent">
        <event-card :event="currentEvent">
          <template slot="flier-image">
            <img src="" ref="image">
          </template>
        </event-card>
      </div>
    </div>
    
    <thc-events @eventClicked="onEventClicked"></thc-events>
  </div>
</template>

<script>
import ThcEvents from '@/views/home/sections/ThcEvents.vue';
import EventCard from '@/components/EventCard.vue';
import { storage } from '../../../firebase';
import { firebase } from '@firebase/app';
import { db } from '@/firebase';

export default {
  name: 'EventsPreview',
  components: { ThcEvents, EventCard },
  data: function () {
    return {
      currentEvent: {
        venue: '',
        date: '',
        timeBegin: '',
        timeEnd: '',
        description: '',
        image: '',
        flier: '',
        order: null,
        active: true,
      },
      fileToUpload: null,
      uploadButtonText: 'Choose Image',
      hasImageChanged: false,
      dbInfo: [],
      dbErrors: [],
    }
  },
  computed: {
    eventId: function () {
      return this.currentEvent['.key'] ? this.currentEvent['.key'] : this.currentEvent.date + '-' + this.currentEvent.venue;
    },
  },
  methods: {
    clearCurrentEvent: function () {
      this.$refs.image.src = '';
      this.fileToUpload = null;
      this.currentEvent = {
        venue: '',
        date: '',
        timeBegin: '',
        timeEnd: '',
        description: '',
        image: '',
        flier: '',
        order: null,
        active: true,
      };
    },
    onEventClicked: function (event) {
      this.currentEvent = JSON.parse(JSON.stringify(event));
      this.fileToUpload = null;
      this.uploadButtonText = 'Change Image';
      this.$refs.image.src = this.currentEvent.flier;
    },
    saveCurrentEvent: function () {
      let self = this;
      // save image first
      if (self.fileToUpload) {
        let uploadTask = storage.ref().child(`events/${this.eventId}-${self.fileToUpload.name}`).put(self.fileToUpload);

        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, 
          function (snapshot) {
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            self.uploadButtonText = `${Math.round(progress)}%`;
            console.log(`file upload: ${progress}%`);
          },
          function(error) {
            switch (error.code) {
              case 'storage/unauthorized':
                break;
              case 'storage/canceled':
                break;
              case 'storage/unknown':
                break;
            }
          },
          function() {
            uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
              console.log(downloadURL);
              self.uploadButtonText = 'Change Image';
              self.currentEvent.flier = downloadURL;

              self.saveDocument();
            })
          });
      } else {
        console.log('theres no img to upload');
        self.saveDocument();
      }
    },
    saveDocument: function () {
      let self = this;
      db.collection("events").doc(self.eventId).set(self.currentEvent).then(function () {
        console.log("Document successfully written!");
        self.dbInfo.push("Saved!");
        let clearInfo = setTimeout(() => { self.dbInfo = []; }, 3000);
      }).catch(function(error) {
        self.dbErrors.push(error);
        console.error("Error writing document: ", error);
      });
    },
    deleteCurrentEvent: function () {
      let self = this;
      db.collection('events').doc(self.eventId).delete().then(function () {
        console.log('document deleted');
        self.clearCurrentEvent();
      }).catch(function (error) {
        console.error('doc failed to delete ', error);
      })
    },
    holdFile: function (event) {
      let self = this;
      this.fileToUpload = event.target.files[0];
      this.uploadButtonText = this.fileToUpload.name;
      let reader = new FileReader();
      reader.onload = function (e) {
        self.$refs.image.src = e.target.result;
      };
      reader.readAsDataURL(this.fileToUpload);
    },
  },
}
</script>

<style lang="scss" scoped>
.input-file {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
  z-index: -1;
}

.input-file:focus + label,
.input-file + label:hover {
    background: linear-gradient(#000, #333);
}

.file-label {
    font-size: .9em;
    color: #ccc;
    background: linear-gradient(#333, #000);
    display: block;
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
}

img {
  border-radius: 20%;
  max-width: 300px;
}
h2 {
  color: #ccc;
}
.form-group {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.form-container div {
  padding: 4px;
}
.current-event-card {
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
}

.checkbox-label {
  color: #ccc;
}

h5 {
  color: #ccc;
}
</style>
