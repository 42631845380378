<template>
  <div id="home">
    <thc-hero/>
    <!-- <crook-divider>
      <h2>
        {{ intro }}
      </h2>
    </crook-divider> -->
    <thc-quote :index="1" :withStyle="{ background: 'linear-gradient(#33122d, #111)', color: '#ccc' }"/>
    <thc-events/>
    <thc-quote :index="0"/>
    <thc-biographies/>
    <thc-contact/>
    <thc-footer></thc-footer>
  </div>
</template>

<script>
import { db } from "../../firebase.js";
import CrookDivider from './sections/CrookDivider.vue';
import ThcHero from './sections/ThcHero.vue';
import ThcBiographies from './sections/ThcBiographies.vue';
import ThcEvents from './sections/ThcEvents.vue';
import ThcContact from './sections/ThcContact.vue';
import ThcQuote from './sections/ThcQuote.vue';
import ThcFooter from './sections/ThcFooter.vue';

export default {
  name: 'Home',
  components: {
    ThcHero,
    CrookDivider,
    ThcEvents,
    ThcBiographies,
    ThcQuote,
    ThcContact,
    ThcFooter,
  },
  data() {
    return {
      intro: ''
    }
  },
  methods: {
    fetchIntro: function () {
      let ref = db.collection('content').doc('intro');
      ref.get().then( snapshot => { 
        this.intro = snapshot.exists ? snapshot.data().text : '';
      });
    },
    track () {
      this.$ga.page('/')
    }
  },
  computed: {},
  created() {},
  mounted() {
    this.track();
  }
};
</script>

<style lang="scss" scoped>
</style>
