import { firebase } from '@firebase/app';
import '@firebase/firestore';
import '@firebase/functions';
import '@firebase/auth';
import '@firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyAYO-r23OVdc2BQg9xJHNx_RcR5v3RsAE0',
  authDomain: 'thehalfwaycrooks-66abf.firebaseapp.com',
  databaseURL: 'https://thehalfwaycrooks-66abf.firebaseio.com',
  projectId: 'thehalfwaycrooks-66abf',
  storageBucket: 'thehalfwaycrooks-66abf.appspot.com',
  messagingSenderId: '340017378659',
};
const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = firebaseApp.firestore();
export const firebaseFunctions = firebaseApp.functions();
export const auth = firebaseApp.auth();
export const storage = firebaseApp.storage();
