<template>
  <div id="thc-footer">
    &copy; 2019 The Halfway Crooks
  </div>
</template>

<script>
export default {
  name: 'ThcFooter',
  components: {},
  methods: {},
  computed: {},
}
</script>

<style lang="scss" scoped>
$purple: #441b3b;
#thc-footer {
  display: flex;
  justify-content: flex-end;
  padding: 18px;
  height: 40px;
  width: 100%;
  // background-color: rgba(90, 0, 90, .6);
  background: linear-gradient( #111, $purple);
  color: #ccc;
  font-size: .7em;
}
</style>
