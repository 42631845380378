<template>
  <div class="event-gallery">
    <div><h1>{{ tag.title }}</h1></div>
    <div class="images-container">
      <div v-for="(image, idx) in images" :key="`${tag.tag}-${image.publicId}`" class="image">
        <img :src="image.thumbnail" @click="selectImage(idx)">
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import VueGallery from 'vue-gallery';

export default {
  name: 'TagGallery',
  components: { VueGallery },
  props: {
    tag: Object,
    title: String,
  },
  data() {
    return {
      images: [],
      galleryIndex: null,
    }
  },
  computed: {
    imageUrls() {
      return this.images.map((img) => img.url);
    },
  },
  methods: {
    setGalleryIndex(image) {
      this.galleryIndex = this.imageUrls.indexOf(image.url);
    },
    selectImage(idx) {
      const imageUrls = this.images.map((img) => img.url);
      this.$emit('select',{ idx, imageUrls })
    },
  },
  created() {
    axios.get(`https://res.cloudinary.com/thehalfwaycrooks/image/list/${this.tag.tag}.json`)
      .then((res) => {
        this.images = res.data.resources.map((img) => {
          return {
            publicId: img.public_id,
            url: `https://res.cloudinary.com/thehalfwaycrooks/image/upload/a_exif/${img.public_id}`,
            thumbnail: `https://res.cloudinary.com/thehalfwaycrooks/image/upload/t_media_lib_thumb/${img.public_id}`,
          }
        });
      });
  },
};
</script>

<style lang="scss" scoped>
h1 {
  color: #ccc;
  margin-bottom: 12px;
  font-size: 1.2rem;
  text-align: center;
}
.images-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.image {
  margin: 4px;
}
img {
  border-radius: 4px;
}
</style>