import { render, staticRenderFns } from "./ThcEvents.vue?vue&type=template&id=19eed733&scoped=true&"
import script from "./ThcEvents.vue?vue&type=script&lang=js&"
export * from "./ThcEvents.vue?vue&type=script&lang=js&"
import style0 from "./ThcEvents.vue?vue&type=style&index=0&id=19eed733&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19eed733",
  null
  
)

export default component.exports