<template>
  <div id="account-create">
    <form v-if="!currentUser" id="account-create-form" @submit.prevent="createAccountClicked">
      <div class="create-email-input">
        <label for="create-email">email</label>
        <input type="text" id="create-email" name="create-email" v-model="email"/>
      </div>
      <div class="password-input">
        <label for="password">Password</label>
        <input type="password" name="password" v-model="password"/>
      </div>
      <button type="submit">Create Account</button>
    </form>
    <div v-if="error.length > 0" class="error">
      <h5>{{error}}</h5>
    </div>
  </div>
</template>

<script>
import { auth } from '../../firebase';
import { mapGetters } from 'vuex';
export default {
  name: 'AccountCreate',
  components: {},
  data: function () {
    return {
      email: '',
      password:'',
      error: [],
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
    ])
  },
  methods: {
    createAccountClicked: function createAccountClicked() {
      let self = this;
      auth.createUserWithEmailAndPassword(this.email, this.password)
      .then(function(data) {
        console.log(`created new user and returned data: ${data}`);
        auth.currentUser.sendEmailVerification().then((data) => {
          console.log('email sent', data);
          self.$router.push('/admin')
        }).catch((error) => {
          console.error('email failed');
          self.error.push(error.message);
        })
      })
      .catch(function(error) {
        self.error.push(error.message);
      });
    },
    logout: function logout() {
      auth.signOut().then(() => {
      }).catch((error) => {
        console.log('error logging out...', error);
      })
    },
    getCurrentUser: function getCurrentUser() {
      return auth.currentUser;
    },
  },
}
</script>

<style lang="scss" scoped>
input {
  padding: 10px;
  background-color: #ccc;
  border: none;
  margin: 4px;
}
label {
  float: left;
  color: #ccc;
  padding-left: 2px;
  margin-bottom: -4px;
}

#account-create {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  min-height: 100px;
}

.create-email-input,
.password-input {
  display: flex;
  flex-flow: column nowrap;
}
.error {
  color: red;
}
</style>
