<template>
  <a id="thc-bio">
    <div class="about">
      <h1 class="section-header">The Band</h1>
      <div class="bio-container">
        <bio-card v-for="(bio, index) in bios" :key="index">
          <template v-if="bio.image" slot="image"><img :src="bio.image"></template>
          <template v-if="bio.description" slot="text">
            <p>{{ bio.description }}</p>
          </template>
        </bio-card>
      </div>
    </div>
  </a>
</template>

<script>
import { db } from '../../../firebase';
import BioCard from '@/components/BioCard.vue';

export default {
  name: 'ThcBiographies',
  components: {
    BioCard
  },
  data: function () {
    return {
      bios: {},
    }
  },
  methods: {},
  firestore() {
    return {
      bios: db.collection('biographies').orderBy("order"),
    };
  },
  created() {
    console.log(this.bios);
  }
}
</script>

<style lang="scss" scoped>
.about {
  background-image: linear-gradient(#111 0%, #153646 20%);
}
.bio-container {
  display: flex;
  flex-wrap: wrap;
}
</style>
