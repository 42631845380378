import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/home/Home.vue';
import Login from './views/admin/Login.vue';
import AccountCreate from './views/admin/AccountCreate.vue';
import CrookDivider from './views/admin/previews/CrookDivider.vue';
import About from './views/admin/previews/About.vue';
import EventsPreview from './views/admin/previews/EventsPreview.vue';
import GalleryPreview from './views/admin/previews/GalleryPreview.vue';
import Messages from './views/admin/dashboard/Messages.vue';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/admin',
      name: 'admin',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "admin" */ './views/admin/Admin.vue'),
      children: [
        { path: 'login', component: Login },
        { path: 'register', component: AccountCreate },
        { path: 'preview/band-summary', component: CrookDivider },
        { path: 'preview/events', component: EventsPreview },
        { path: 'preview/about', component: About },
        { path: 'preview/gallery', component: GalleryPreview },
        { path: 'messages', component: Messages },
      ],
    },
    {
      path: '/gallery',
      name: 'ThcGallery',
      component: () => import(/* webpackChunkName: "ThcGallery" */ './views/ThcGallery.vue'),
    },
  ],
});
