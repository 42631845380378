<template>
  <div class="event-card reverse" @click="onClick" v-if="event">
    <div class="event-card-img">
      <slot name="flier-image">
        <img :src="event.flier" alt="Event Flier">
      </slot>
    </div>
    <div class="event-card-text">
      <h2>{{ event.date | dateFormatted }}</h2>
      <h2>{{ eventTime }}</h2>
      <h3>{{ event.venue }}</h3>
      <h1>{{ event.description}}</h1>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'EventCard',
  components: {},
  props: {
    event: Object,
    image: String,
    reverse: Boolean,
  },
  filters: {
    dateFormatted(text) {
      return moment(text).format('dddd, MMM Do YYYY');
    }
  },
  methods: {
    onClick: function handleClickEvent() {
      this.$emit('eventClicked', this.event);
    }
  },
  computed: {
    eventTime: function () {
      let dateFormatted = '';
      dateFormatted += this.event.timeBegin.length > 0 ? this.event.timeBegin : '';
      dateFormatted += `${this.event.timeEnd.length > 0 ? ` - ${this.event.timeEnd}` : ''}`;
      return dateFormatted;
    },
  },
}
</script>

<style lang="scss" scoped>
.event-card {
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  justify-content: space-between;
  margin-top: 10px;
  @media (min-width: 800px) {
    margin-left: 40px;
    margin-right: 120px;
  }
  // border: 1px solid #ccc;
}

.event-card:nth-child(2) {
  flex-flow: row-reverse wrap;
}

.event-card-img {
  align-content: center;
  margin: 20px 0 20px 20px;
  @media (max-width: 600px) {
    width: 100%;
    text-align: center;
  }
}

.event-card-text {
  padding: 20px;
  color: #ccc;
  @media (max-width: 600px) {
    width: 100%;
    text-align: center;
  }
}

.reverse {
  flex-flow: row-reverse wrap;
}

img {
  border-radius: 20%;
  max-width: 300px;
}
</style>
