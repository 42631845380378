<template>
  <div class="bio-card">
    <div class="bio-card-image">
      <slot name="image"></slot>
    </div>
    <div class="bio-card-text">
      <slot name="text"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bio-card',
  components: {},
  methods: {},
  props: {
    imgUrl: String,
    description: String,
  },
  computed: {},
  created() {},
};
</script>


<style lang="scss" scoped>
$break-small: 800px;
$break-large: 1200px;

.bio-card {
  display: flex;
  flex-direction: row;
  color: #ccc;
  max-width: 45vw;
  @media (max-width: $break-small) {
    max-width: 100vw;
  }
  margin: 10px;
}
.bio-card-image {
  // width: 32%;
  align-self: center;
  max-width: 210px;
}
.bio-card-image img {
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.bio-card-text {
  max-width: 68%;
  padding: 0 10px;
  @media (max-width: $break-small) {
    font-size: 8pt;
  }
}
</style>