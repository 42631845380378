<template>
  <div :class="dividerClass">
    <div class="band-summary">
      <slot>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CrookDivider',
  components: {},
  props: {
    reverse: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    dividerClass: function getDividerClass() {
      return this.reverse ? 'divider flex-row-reverse' : 'divider';
    },
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>

$blue-green: #21556d;
$olive-green: #595c41;
$pink: #800080;
$purple: #32122c;

// div {
//   border: 1px solid #ccc;
// }

.flex-row-reverse {
  flex-direction: row-reverse;
}
.divider {
  width: 100vw;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  background-color: $purple;
  background-image: url('../../../assets/img/the_crook_330x328.png'); /* fallback */
  // background-image: url('../../../assets/img/the_crook_330x328.png'), linear-gradient($purple, #111);
  background-repeat: no-repeat;
  background-size: 130px;
  background-position-y: 0;
  animation: crook-appears 1s forwards;
}

@keyframes crook-appears {
  0% {
    background-position-y: 100px, 0;
    background-position-x: -100px, 0;
  }
}

.band-summary {
  align-self: center;
  color: #ccc;
  padding: 10px;
  margin: 0 24px 0 100px;
  text-align: center;
  text-shadow: 2px 2px 8px #000000;
  align-self: center;
  // height: 100%;
  width: 100%;
  position: relative;
  left: 24px;
}

.band-summary h2 {
  @media (max-width: 820px) {
    font-size: .9em;
  }
  @media (max-width: 600px) {
    font-size: .7em;
  }
}
</style>
