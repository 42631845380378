<template>
  <div id="band-summary-preview">
    <crook-divider>
      <h2>{{ summaryText }}</h2>
    </crook-divider>
    <div class="form-container">
      <form @submit.prevent="saveChanges">
        <textarea v-model="summaryText"></textarea>
        <div class="action-area">
          <button class="button" type="submit">Save</button>
          <div v-if="dbErrors.length > 0">
            <div v-for="(error, index) in dbErrors" :key="index" class="error">{{ error }}</div>
          </div>
          <div v-if="dbInfo.length > 0">
            <div v-for="(info, index) in dbInfo" :key="index" class="info">{{ info }}</div>
          </div>
        </div>
      </form>
    </div>
    <div class="zig-zag"></div>
  </div>
</template>

<script>
import { db } from '../../../firebase';
import CrookDivider from '@/views/home/sections/CrookDivider.vue';
export default {
  name: 'BandSummaryPreview',
  components: {
    CrookDivider
  },
  data: function () {
    return {
      summaryText: '',
      dbErrors: [],
      dbInfo: [],
    }
  },
  methods: {
    fetchIntro: function () {
      let ref = db.collection('content').doc('intro');
      ref.get().then( snapshot => { 
        this.summaryText = snapshot.exists ? snapshot.data().text : ''; 
      });
    },
    saveChanges: function () {
      let self = this;
      db.collection("content").doc("intro").set({
        text: this.summaryText
      }).then(function() {
        console.log("Document successfully written!");
        self.dbInfo.push("Saved!");
        let clearInfo = setTimeout(() => { 
          self.dbInfo = [];
        }, 3000);
      }).catch(function(error) {
        self.dbErrors.push(error);
        console.error("Error writing document: ", error);
      });
    }
  },
  computed: {},
  created() {
    this.fetchIntro();
  }
}
</script>

<style lang="scss" scoped>
$purple: #441b3b;
#band-summary-preview {
  padding-top: 20px;
}
.form-container textarea {
  width: 100vw;
  min-height: 60px;
}

.action-area {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}
.action-area .error {
  margin-left: 20px;
}
</style>
