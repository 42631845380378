<template>
  <a id="events">
    <div class="thc-events">
      <h1 class="section-header">Events</h1>
      <div class="event" v-for="event in eventsFs" :key="event['.key']">
        <div class="event-card-container"><event-card :event="event" @eventClicked="onEventClicked"/></div>
      </div>
    </div>
  </a>
</template>

<script>
import { db } from '../../../firebase';
import EventCard from '@/components/EventCard.vue';

export default {
  name: 'ThcEvents',
  components: { EventCard },
  methods: {
    onEventClicked: function (event) {
      this.$emit('eventClicked', event)
    },
  },
  data() {
    return {
      eventsFs: {},
    };
  },
  computed: {},
  firestore() {
    return {
      eventsFs: db.collection('events').where('active', "==", true).orderBy('date', 'asc'),
    };
  },
};
</script>

<style lang="scss" scoped>
.events {
  min-height: 100vh;
}
.section-header {
  text-shadow: 2px 2px 8px purple;
  @media (min-width: 1000px) {
    position: absolute;
    font-size: 3em;
    margin-top: 40px;
  }
}
.event {
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  justify-content: flex-start;
  margin-top: 10px;
}

@media (min-width: 800px) {
  .event:nth-child(even) {
    justify-content: flex-end;
    text-align: right;
  }
}
</style>
