<template>
  <div id="login">
    <form v-if="!currentUser" id="login-form" @submit.prevent="login">
      <div class="login-email-input">
        <label for="login-email">email</label>
        <input type="text" id="login-email" name="login-email" v-model="email"/>
      </div>
      <div class="password-input">
        <label for="password">Password</label>
        <input type="password" name="password" v-model="password"/>
      </div>
      <button type="submit">Login</button>
    </form>
    <div v-if="loginError" class="error">
      <h5>{{loginError}}</h5>
    </div>
  </div>
</template>

<script>
import { auth } from '../../firebase';
import { mapMutations, mapGetters } from 'vuex';
export default {
  name: 'Login',
  components: {},
  data: function () {
    return {
      email: '',
      password:'',
      loginError: null,
    }
  },
  methods: {
    ...mapMutations([
      'setCurrentUser'
    ]),
    login: function login() {
      auth.signInWithEmailAndPassword(this.email, this.password).then((data) => {
        console.log(data);
        this.loginError = null;
      }).catch((error) => {
        console.error(error.code, error.message);
        this.loginError = error.message;
      })
    },
    logout: function logout() {
      auth.signOut().then(() => {
        console.log('logged out');
        this.setCurrentUser();
      }).catch((error) => {
        console.log('error logging out...', error);
      })
    },
  },
  computed: {
    ...mapGetters([
      'currentUser',
    ]),
  },
}
</script>

<style lang="scss" scoped>
input {
  padding: 10px;
  background-color: #ccc;
  border: none;
  margin: 4px;
}
label {
  float: left;
  color: #ccc;
  padding-left: 2px;
  margin-bottom: -4px;
}

#login {
  display: flex;
  align-items: center;
  min-height: 100px;
}

.login-email-input,
.password-input {
  display: flex;
  flex-flow: column nowrap;
}
.user-info {
  display: flex;
  flex-flow: column nowrap;
  color: #ccc;
}
.error {
  color: red;
}
</style>
