import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from './firebase';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUser: null,
  },
  mutations: {
    setCurrentUser(state, user) {
      state.currentUser = auth.currentUser;
    },
  },
  getters: {
    currentUser: state => state.currentUser,
  },
  actions: {},
});
