<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { auth } from './firebase';

export default {
  name: 'App',
  components: {},
  methods: {
    ...mapMutations([
      'setCurrentUser'
    ])
  },
  created() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setCurrentUser();
      } else {
        // User is signed out.
      }
    });
  },
}
</script>

<style lang="scss" scoped>
</style>
