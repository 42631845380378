// import './firebase';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import './assets/css/global.scss';

import Vue from 'vue';
import VueFirestore from 'vue-firestore';
import VeeValidate from 'vee-validate';
import VueScrollTo from 'vue-scrollto';
import VueAnalytics from 'vue-analytics';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

// import injectInitialState from './utils/inject-initial-state';
import router from './router';
import store from './store';

import App from './App.vue';

Vue.config.productionTip = false;
const isProd = process.env.NODE_ENV === 'production';

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('vue-ctk-date-time-picker', VueCtkDateTimePicker);
Vue.use(VueFirestore);
Vue.use(VeeValidate);
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});


Vue.use(VueAnalytics, {
  id: 'UA-137267734-1',
  router,
  debug: {
    enabled: !isProd,
    sendHitTask: isProd,
  },
});


// new Vue({
//   router,
//   store,
//   render: h => h(App),
// }).$mount('#app');

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
